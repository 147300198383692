import './MauticTrialForm.scss'

import React, { FunctionComponent } from 'react'


import TrialFormDE from './TrialForms/TrialFormDE'
import TrialFormES from './TrialForms/TrialFormES'
import TrialFormFR from './TrialForms/TrialFormFR'
import TrialFormIT from './TrialForms/TrialFormIT'
import TrialFormRU from './TrialForms/TrialFormRU'
import GenericTrialForm from './TrialForms/GenericTrialForm'

const MauticTrialForm: FunctionComponent = () => {
  switch (process.env.LOCALE || `en`) {
    case `de`:
      return <TrialFormDE />
    case `es`:
      return <TrialFormES />
    case `fr`:
      return <TrialFormFR />
    case `ru`:
      return <TrialFormRU />
    case `it`:
      return <TrialFormIT />
    case `hu`:
    case `cs`:
    default:
      return <GenericTrialForm />
  }
}

export default MauticTrialForm
